import React from 'react'

const SvgCloseClear = () => {
  return (
    <svg
      width="36"
      height="36"
      viewBox="0 0 36 36"
      style={{ enableBackground: 'new 0 0 25 25' }}
      className="fill-current"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path d="M0.968351 0.642912C1.35844 0.252818 1.99091 0.252818 2.381 0.642912L18.0028 16.2647L33.6246 0.642912C34.0147 0.252818 34.6471 0.252818 35.0372 0.642912C35.4273 1.03301 35.4273 1.66547 35.0372 2.05557L19.4154 17.6774L35.0372 33.2991C35.4273 33.6892 35.4273 34.3217 35.0372 34.7118C34.6471 35.1019 34.0147 35.1019 33.6246 34.7118L18.0028 19.09L2.381 34.7118C1.99091 35.1019 1.35844 35.1019 0.968351 34.7118C0.578258 34.3217 0.578258 33.6892 0.968351 33.2991L16.5901 17.6774L0.968351 2.05557C0.578258 1.66547 0.578258 1.03301 0.968351 0.642912Z" />
    </svg>
  )
}

export default SvgCloseClear
