import React, { useState, useEffect } from 'react'
import PropTypes from 'prop-types'
import { useForm } from 'react-hook-form'
import axios from 'axios'

// Resolver
import { SignUpResolver } from '@src/resolvers/SignUp'

// Inputs
import Input from './fields/Input'
import Textarea from './fields/Textarea'

const FormSignUp = ({
  wrapperClass = '',
  submitLabel = 'Submit',
  isLabelHidden = false,
  labelClassName = '',
  leadName = 'Free Consulation',
  messageLabel = 'Questions or Comments',
}) => {
  const [submitting, setSubmitting] = useState(false)

  const {
    register,
    resetField,
    setValue,
    handleSubmit,
    reset,
    formState: { errors, isDirty, isValid },
  } = useForm({
    // mode: 'onChange',
    resolver: SignUpResolver.resolver,
  })

  useEffect(() => {
    setValue('field_126636982', leadName)
  }, [])

  const onSubmit = async (data) => {
    setSubmitting(true)

    await axios
      .post(
        `${process.env.GATSBY_FORMSTACK_API}/${process.env.GATSBY_FORMSTACK_KEY}/4818068`,
        data,
        {
          headers: {
            'Content-Type': 'application/json',
            'X-Requested-With': 'XMLHttpRequest',
          },
        }
      )
      .then((res) => {
        if (res.status === 200) {
          alert('Successfully')

          // Clear fields
        }
        setSubmitting(false)
        reset()
      })
      .catch((err) => {
        alert('Error')
        // console.log(err);
        setSubmitting(false)
      })
  }

  return (
    <form className={wrapperClass} onSubmit={handleSubmit(onSubmit)}>
      <div className="form-row">
        <div className="form-col">
          <Input
            register={register}
            label="First Name"
            name="field_125474663"
            type="text"
            error={errors.field_125474663?.message}
            isRequired={true}
            isLabelHidden={isLabelHidden}
            labelClassName={labelClassName}
          />
        </div>
        <div className="form-col">
          <Input
            register={register}
            label="Last Name"
            name="field_125474662"
            type="text"
            error={errors.field_125474662?.message}
            isRequired={true}
            isLabelHidden={isLabelHidden}
            labelClassName={labelClassName}
          />
        </div>
      </div>
      <div className="form-row">
        <div className="form-col">
          <Input
            register={register}
            label="Email Address"
            name="field_125471274"
            type="email"
            error={errors.field_125471274?.message}
            isRequired={true}
            isLabelHidden={isLabelHidden}
            labelClassName={labelClassName}
          />
        </div>
        <div className="form-col">
          <Input
            register={register}
            label="Phone Number"
            name="field_125474666"
            type="text"
            error={errors.field_125474666?.message}
            isRequired={true}
            isLabelHidden={isLabelHidden}
            labelClassName={labelClassName}
          />
        </div>
      </div>

      <div className="form-row">
        <div className="form-col">
          <Textarea
            register={register}
            label={messageLabel}
            name="field_125474669"
            error={errors.field_125474669?.message}
            isRequired={false}
            isLabelHidden={isLabelHidden}
            labelClassName={labelClassName}
          />
        </div>
      </div>

      {/* Robot Verifier */}
      <div className="hidden">
        <Input
          register={register}
          label="Automaton"
          name="automaton"
          type="text"
          error={errors.automaton?.message}
          isRequired={false}
        />
        <Input
          register={register}
          label="Lead Name"
          name="field_126636982"
          type="text"
          error={errors.field_126636982?.message}
          isRequired={false}
        />
      </div>

      <div className="form-row">
        <div className="form-col">
          <div className="form-control form-submit">
            {/* {submitting && <>Spinner</>} */}
            <button type="submit" className="primary-button large">
              {submitLabel}
            </button>
          </div>
        </div>
      </div>
    </form>
  )
}

FormSignUp.propTypes = {
  wrapperClass: PropTypes.string,
  submitLabel: PropTypes.string,
  isLabelHidden: PropTypes.bool,
  labelClassName: PropTypes.string,
  leadName: PropTypes.string,
  messageLabel: PropTypes.string,
}

export default FormSignUp
