import React from 'react'

const SvgLogoStacked = () => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      viewBox="0 0 218 236"
      style={{ enableBackground: 'new 0 0 218 236' }}
      className="fill-current"
    >
      <path d="M74.1998 189.288H55.2185C45.8622 189.288 49.7338 179.573 49.7338 179.573L108.506 49.0606L138.457 116.152L102.699 196.575H118.615L181.582 54.566C189.916 35.7827 197.982 36.3224 197.982 36.3224H206.263V30.5471H173.839C174.86 27.3086 176.796 26.391 180.936 25.2575C184.7 24.232 190.293 24.6098 195.616 22.2889C202.983 19.1043 204.273 15.8658 204.435 10.7382C204.596 5.55656 201.585 1.18457 201.585 1.18457C201.585 1.18457 202.015 5.34066 198.95 9.44278C194.594 15.2181 187.819 14.8403 187.819 14.8403C194.917 11.8177 193.788 5.77246 193.788 5.77246C192.175 8.3093 191.476 9.11893 185.184 10.1984C178.947 11.2779 175.559 13.1671 171.473 18.1868C167.386 23.2065 166.794 30.5471 166.794 30.5471H149.749V36.2685H161.148C174.215 36.2685 168.408 49.8702 168.408 49.8702L152.169 87.275L119.637 15.9738L102.86 15.9198L26.5045 177.899C20.9122 189.774 12.255 189.234 12.255 189.234H1.76953V196.791H74.1998V189.288Z" />
      <path d="M206.962 189.234C206.962 189.288 199.273 190.367 193.788 178.385L166.418 118.526L153.298 147.565L166.257 176.712C166.257 176.712 171.742 189.234 160.88 189.234H143.189V196.79H215.888V189.234H206.962Z" />
      <path d="M36.7217 225.235H42.3677V229.715C41.0772 230.687 38.765 231.659 35.9689 231.497C33.4417 231.335 31.1833 230.039 30.0541 228.096C28.7635 226.099 28.3871 223.292 29.1937 220.81C30.0003 218.273 32.5813 215.466 36.3453 215.466C39.7867 215.466 41.3999 216.707 42.6366 217.733L44.8412 215.196C43.6045 214.332 41.3999 212.443 36.6142 212.443C31.0757 212.443 26.9891 215.79 25.6448 220C24.3543 224.048 25.4835 227.61 26.8815 229.715C28.2796 231.874 31.2908 234.519 36.6142 234.519C42.3677 234.519 45.8091 231.227 45.8091 231.227V222.429H38.2273L36.7217 225.235Z" />
      <path d="M204.166 231.011V224.804H213.737V221.889H204.166V215.628H214.92L215.727 212.713H200.832V233.98H215.888L215.081 231.011H204.166Z" />
      <path d="M138.081 223.185L131.09 212.713H127.272V233.98H130.553V217.733L138.027 228.474L145.555 217.733V233.98H148.889V212.713H145.071L138.081 223.185Z" />
      <path d="M53.282 231.011V224.804H62.9071V221.889H53.282V215.628H64.0363L64.8429 212.713H50.002V233.98H65.0042L64.1976 231.011H53.282Z" />
      <path d="M187.281 225.235H192.874V229.715C191.583 230.687 189.271 231.659 186.475 231.497C183.948 231.335 181.689 230.039 180.56 228.096C179.269 226.099 178.893 223.292 179.7 220.81C180.506 218.273 183.087 215.466 186.851 215.466C190.293 215.466 191.906 216.707 193.142 217.733L195.347 215.196C194.11 214.332 191.906 212.443 187.12 212.443C181.582 212.443 177.495 215.79 176.151 220C174.86 224.048 175.989 227.61 177.387 229.715C178.785 231.874 181.797 234.519 187.12 234.519C192.874 234.519 196.315 231.227 196.315 231.227V222.429H188.733L187.281 225.235Z" />
      <path d="M11.3946 212.713L1.76953 233.98H5.21091L7.41554 228.96H18.7613L20.966 233.98H24.5149L14.8898 212.713H11.3946ZM8.75983 226.045L13.0616 216.438L17.4171 226.045H8.75983Z" />
      <path d="M162.171 212.713L152.545 233.98H155.987L158.191 228.96H169.537L171.742 233.98H175.291L165.666 212.713H162.171ZM159.536 226.045L163.837 216.438L168.193 226.045H159.536Z" />
      <path d="M121.465 212.713H118.131V233.98H121.465V212.713Z" />
      <path d="M91.998 215.736H99.1497V233.98H102.699V215.736H108.882L109.743 212.713H91.998V215.736Z" />
      <path d="M85.2776 228.312L72.8026 212.713H69.4688V233.98H72.9101L73.0177 218.165L85.5464 233.98H88.6652V212.713H85.2776V228.312Z" />
    </svg>
  )
}

export default SvgLogoStacked
