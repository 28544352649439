import React from 'react'

const SvgClose = () => {
  return (
    <svg
      role="img"
      xmlns="http://www.w3.org/2000/svg"
      viewBox="0 0 25 25"
      style={{ enableBackground: 'new 0 0 25 25' }}
      className="fill-current"
      aria-label="Close"
    >
      <title>Close</title>
      <path d="M23.6687 1.40712C23.3287 1.06804 22.867 0.877483 22.3855 0.877483C21.904 0.877483 21.4423 1.06804 21.1022 1.40712L12.2012 10.2439L3.30015 1.38901C2.96007 1.04993 2.49836 0.859375 2.01687 0.859375C1.53538 0.859375 1.07368 1.04993 0.733595 1.38901C0.0236975 2.09523 0.0236975 3.23605 0.733595 3.94227L9.63462 12.7972L0.733595 21.6521C0.0236975 22.3583 0.0236975 23.4991 0.733595 24.2053C1.44349 24.9116 2.59025 24.9116 3.30015 24.2053L12.2012 15.3504L21.1022 24.2053C21.8121 24.9116 22.9589 24.9116 23.6687 24.2053C24.3786 23.4991 24.3786 22.3583 23.6687 21.6521L14.7677 12.7972L23.6687 3.94227C24.3604 3.25416 24.3604 2.09523 23.6687 1.40712Z" />
    </svg>
  )
}

export default SvgClose
