// extracted by mini-css-extract-plugin
export var bestWebsites = "Form-module--best-websites--0ec1e";
export var bordered = "Form-module--bordered--f229d";
export var closeAlt = "Form-module--closeAlt--ffbf5";
export var closeM1 = "Form-module--closeM1--6115c";
export var form = "Form-module--form--2a018";
export var formSubmit = "Form-module--form-submit--d63d3";
export var formwrap = "Form-module--formwrap--66238";
export var heading = "Form-module--heading--746f8";
export var hero = "Form-module--hero--1786d";
export var heroBg = "Form-module--hero-bg--d7188";
export var imagebg = "Form-module--imagebg--6841c";
export var imagelogo = "Form-module--imagelogo--51825";
export var imagewrap = "Form-module--imagewrap--7ca6d";
export var m1 = "Form-module--m1--a7648";
export var m2 = "Form-module--m2--3726c";
export var mono = "Form-module--mono--d5c6e";
export var themeAlt = "Form-module--theme-alt--6638d";
export var themeDefault = "Form-module--theme-default--0e60f";