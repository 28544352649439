import React from 'react'
import ModalForm from '@components/global/modal/Form'
import FormSignUp from '@components/global/forms/SignUp'
import { template } from 'lodash'
import clsx from 'clsx'

const Modals = () => {
  const [active, setActive] = React.useState('M1A')
  const [type, setType] = React.useState('a')

  const modalFunc = React.useRef()

  React.useEffect(() => {
    if (active === 'M1B' || active === 'M2B') return setType('b')
    return setType('a')
  }, [active])

  const findTemplate = (activeTemplate) => {
    switch (activeTemplate) {
      case 'M1A':
      case 'M1B':
        return 'm1'
      case 'M2A':
      case 'M2B':
        return 'm2'
      case 'M3':
        return 'best-websites'
      case 'M4':
        return 'default'
      case 'alt':
        return 'alternative'
      default:
        return 'default'
    }
  }

  return (
    <section className="flex flex-col justify-center items-center">
      <div className="py-10">
        <div className="flex flex-col justify-start">
          <label>Version</label>
          <select
            className="px-6 border border-neutral-600"
            value={active}
            onChange={(e) => {
              setActive(e.target.value)
            }}
          >
            <option value="M1A">M1A</option>
            <option value="M1B">M1B</option>
            <option value="M2A">M2A</option>
            <option value="M2B">M2B</option>
            <option value="M3">M3</option>
            <option value="M4">M4</option>
            <option value="alt">Alternative</option>
          </select>
        </div>
      </div>

      <button type="button" onClick={(e) => modalFunc.current.openModal()}>
        Open Modal
      </button>

      <ModalForm
        childFunc={modalFunc}
        title={
          active === 'M4' || active === 'M3'
            ? 'Ready To Build An <br>Award-Winning Website?'
            : 'Sign Up Today For A <br>Free Consultation'
        }
        description={
          active === 'M4' || active === 'M3'
            ? 'Sign Up Today For A Free Consultation'
            : 'See how Agent Image can help you grow your real estate business.'
        }
        template={findTemplate(active)}
        type={type}
      >
        <FormSignUp
          submitLabel={
            active === 'M3'
              ? 'Get Started'
              : active === 'M4'
              ? 'Unlock Next-Level Growth!'
              : 'Get Started Today'
          }
          labelClassName={clsx({
            'desktop-only': active === 'M3' || active === 'M4',
          })}
          messageLabel="Questions or Comments"
        />
      </ModalForm>
      {active}
    </section>
  )
}

export default Modals
