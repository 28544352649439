import React, { Fragment, useEffect, useState } from 'react'
import PropTypes from 'prop-types'
import { Dialog, Transition } from '@headlessui/react'
import parse from 'html-react-parser'

import ElementImage from '@components/global/element/Image'
import ModalShowcaseBestWebsitesBg from '/content/assets/form/best-websites-background.png'
import ModalShowcaseBestWebsites from '/content/assets/form/best-websites-devices.png'
import SvgLogoStacked from '@svg/logostacked.inline'
import SvgClose from '@svg/close.inline'
import SvgCloseClear from '@svg/close-clear.inline'
import * as cx from './Form.module.scss'
import clsx from 'clsx'

const ModalForm = ({
  wrapperClassName,
  children,
  childFunc,
  title,
  desktopTitle,
  mobileTitle,
  description = 'Sign Up Today For A Free Consultation',
  template = 'default',
  type = 'a',
}) => {
  const [isOpen, setIsOpen] = useState(false)

  useEffect(() => {
    if (childFunc) {
      childFunc.current = { openModal, closeModal }
    }
  })

  const openModal = () => {
    setIsOpen(true)
  }

  const closeModal = () => {
    setIsOpen(false)
  }

  const getTemplate = (selectedTemplate) => {
    switch (selectedTemplate) {
      case 'm1':
        return (
          <div className={clsx([cx.m1], wrapperClassName)}>
            <div className={cx.hero}>
              <div className={cx.mono}>
                <SvgLogoStacked />
              </div>
              <div className={cx.heading}>
                <h2 className="heading-3 text-center">
                  {title !== undefined && parse(title)}
                  {title === undefined && (
                    <>
                      <span className="desktop-only">
                        {parse(desktopTitle)}
                      </span>
                      <span className="mobile-only">{parse(mobileTitle)}</span>
                    </>
                  )}
                </h2>
                {type === 'a' && (
                  <p className="body-default large text-lg mt-4 text-center">
                    {parse(description)}
                  </p>
                )}
              </div>
            </div>
            <div className={cx.form}>{children}</div>
          </div>
        )
      case 'm2':
        return (
          <div className={clsx([cx.m2], wrapperClassName)}>
            <div className={cx.form}>
              <div
                className={clsx([
                  cx.heading,
                  { '!mb-3 md:!mb-11': type === 'b' },
                ])}
              >
                <h2 className="heading-3 text-center">
                  {title !== undefined && parse(title)}
                  {title === undefined && (
                    <>
                      <span className="desktop-only">
                        {parse(desktopTitle)}
                      </span>
                      <span className="mobile-only">{parse(mobileTitle)}</span>
                    </>
                  )}
                </h2>
                {type === 'a' && (
                  <p className="subtitle-5 text-center">{parse(description)}</p>
                )}
              </div>

              {children}
            </div>
          </div>
        )
      //M3
      case 'best-websites':
        return (
          <div className={clsx([cx.bestWebsites], wrapperClassName)}>
            <div className={cx.hero}>
              <div
                className={cx.heroBg}
                style={{
                  backgroundImage: `url(${ModalShowcaseBestWebsitesBg})`,
                }}
              ></div>
              <ElementImage
                src={ModalShowcaseBestWebsites}
                alt={'Popup Form'}
                lazy={false}
              />
            </div>
            <div className={cx.form}>
              <div className={cx.heading}>
                <h2 className="heading-3">
                  {title !== undefined && parse(title)}
                  {title === undefined && (
                    <>
                      <span className="desktop-only">
                        {parse(desktopTitle)}
                      </span>
                      <span className="mobile-only">{parse(mobileTitle)}</span>
                    </>
                  )}
                </h2>
                <p className="subtitle-5">{parse(description)}</p>
              </div>

              {children}
            </div>
          </div>
        )
      //alt modal theme
      case 'alternative':
        return (
          <div className={clsx([cx.themeAlt], wrapperClassName)}>
            <div className={cx.hero}>
              <div className={cx.mono}>
                <SvgLogoStacked />
              </div>
              <div className={cx.heading}>
                <h2 className="subtitle-2">
                  {title !== undefined && parse(title)}
                  {title === undefined && (
                    <>
                      <span className="desktop-only">
                        {parse(desktopTitle)}
                      </span>
                      <span className="mobile-only">{parse(mobileTitle)}</span>
                    </>
                  )}
                </h2>
                <p className="subtitle-5">{parse(description)}</p>
              </div>
              <div
                className={cx.imagebg}
                style={{
                  backgroundImage: `url(https:///resources.agentimage.com/redesign/local/modal-showcase.png)`,
                }}
              ></div>
            </div>

            <div className={cx.form}>{children}</div>
          </div>
        )
      //M4
      case 'default':
      default:
        return (
          <div className={clsx([cx.themeDefault], wrapperClassName)}>
            <div className={cx.imagewrap}>
              <div className={cx.imagelogo}>
                <SvgLogoStacked />
              </div>

              <div
                className={cx.imagebg}
                style={{
                  backgroundImage: `url(https:///resources.agentimage.com/redesign/local/modal-showcase.png)`,
                }}
              ></div>
              <div className={clsx(cx.heading)}>
                <h2 className="heading-3">
                  {title !== undefined && parse(title)}
                  {title === undefined && <span>{parse(desktopTitle)}</span>}
                </h2>
                <p className="subtitle-5">{parse(description)}</p>
              </div>
            </div>
            <div className={cx.formwrap}>
              <div className={clsx(cx.heading)}>
                <h2 className="heading-3">
                  {title !== undefined && parse(title)}
                  {title === undefined && <span>{parse(desktopTitle)}</span>}
                </h2>
                <p className="subtitle-5">{parse(description)}</p>
              </div>

              {children}
            </div>
          </div>
        )
    }
  }

  return (
    <Transition appear show={isOpen} as={Fragment}>
      <Dialog as="div" className="modal" onClose={closeModal}>
        <div className="modal-container">
          <Transition.Child
            as={Fragment}
            enter="ease-out duration-300"
            enterFrom="opacity-0"
            enterTo="opacity-100"
            leave="ease-in duration-200"
            leaveFrom="opacity-100"
            leaveTo="opacity-0"
          >
            <Dialog.Overlay className="modal-overlay" />
          </Transition.Child>
          {/* This element is to trick the browser into centering the modal contents. */}
          <span className="modal-tricker" aria-hidden="true">
            &#8203;
          </span>
          <Transition.Child
            as={Fragment}
            enter="ease-out duration-300"
            enterFrom="opacity-0 scale-95"
            enterTo="opacity-100 scale-100"
            leave="ease-in duration-200"
            leaveFrom="opacity-100 scale-100"
            leaveTo="opacity-0 scale-95"
          >
            <div
              className={clsx('modal-content', {
                '!max-w-[47rem]': template === 'm2' || template === 'm1',
                [cx['bordered']]: template === 'm2',
              })}
            >
              <button
                className={clsx('close-button', {
                  [cx['closeAlt']]: template === 'm2' || template === 'm1',
                  [cx['closeM1']]: template === 'm1',
                })}
                onClick={(e) => setIsOpen(false)}
              >
                {template === 'm2' || template === 'm1' ? (
                  <SvgCloseClear />
                ) : (
                  <SvgClose />
                )}
              </button>

              {getTemplate(template)}
            </div>
          </Transition.Child>
        </div>
      </Dialog>
    </Transition>
  )
}

ModalForm.propTypes = {
  children: PropTypes.any.isRequired,
  wrapperClassName: PropTypes.string,
  childFunc: PropTypes.any,
  title: PropTypes.string,
  desktopTitle: PropTypes.string,
  mobileTitle: PropTypes.string,
  description: PropTypes.string,
  template: PropTypes.oneOf([
    'default',
    'alternative',
    'best-websites',
    'm1',
    'm2',
  ]),
  type: PropTypes.string,
}

export default ModalForm
